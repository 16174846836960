import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        currentLang: 'RU',
        RU: {
            contentIntro: {
                hello: 'Привет! Меня зовут Владимир, и я занимаюсь фронтенд разработкой.',
                about: 'Я пишу на JavaScript используя фреймворк Vue.js (и Nuxt.js). Это просто мой сайт-визитка и ничего более.\n' +
                    'Если вам интересно, то ниже можно найти больше информации обо мне.',
                birds: 'Ах да, пожалуйста, не пугайте моих птиц! Они очень чувствительны! ;)'
            },
            contentSkills: {
                title: 'В своих проектах я использую:',
                subtitle: 'Всё, что касается веб разработки:',
                tags: 'Вот ещё всякие штуки, которые я знаю использую:',
                more: 'Что ещё на бекграунде:'
            },
            contentAbout: {
                title: 'Обо мне',
                text: {
                    p1: 'В 2005 году я закончил Радиотехнический факультет Уральского государственного технического университета (сегодня он входит в состав Уральского федерального университета).',
                    p2: 'С тех пор я работал в самых разных областях IT - занимался разработкой систем для телеметрии и управления оборудованием на промышленных предприятиях, был системным администратором в самых различных компаниях, был менеджером проектов в телеком компании.',
                    p3: 'Параллельно с основной работой я всегда создавал сайты. Первый из них я создал ещё в 2001 году. Это был простой набор из нескольких .html, .css и .js файлов, посвященный творчеству любимого писателя фантаста.',
                    p4: 'Сегодня, спустя 20 лет, и множество других проектов, я до сих пор помню, как он выглядел. Я помню детали рабочего процесса, помню эту табличную вёрстку в notepad, помню это ощущение магии, когда всё, что ты делал, вдруг оживает и начинает работать. Осознание того факта, что результат твоей работы в любой момент может увидеть любой человек на нашей планете.',
                    p5: 'Наверное, именно поэтому я считаю веб разработку своим призванием - делом, которое я искренне люблю по сей день.'
                }
            },
            contentContacts: {
                title: 'Контакты',
                info: 'На данный момент я живу в Санкт-Петербурге. Я всегда открыт для самых различных предложений в рамках моих компетенций или за их пределами.',
            }
        },
        EN: {
            contentIntro: {
                hello: 'Hello! I\'m Vladimir and i am a frontend developer.',
                about: 'I use JavaScript with Vue.js (and Nuxt.js) framework. This is just my Homepage and nothing more.\n' +
                    'If you are interested, you can find more information about me below.',
                birds: 'Please don\'t frighten my birds! They are very sensitive! ;)'
            },
            contentSkills: {
                title: 'In my projects I use:',
                subtitle: 'Everything related to web development:',
                tags: 'Here are some other things I know and use:',
                more: 'What else is on the background:'
            },
            contentAbout: {
                title: 'About me',
                text: {
                    p1: 'In 2005 I graduated from the Radio Engineering Faculty of the Ural State Technical University (today it is part of the Ural Federal University).',
                    p2: 'Since then, I have worked in various areas of IT - I was engaged in the development of systems for telemetry and equipment control at industrial enterprises, I was a system administrator in various companies, I was a project manager in a telecom company.',
                    p3: 'In parallel with my main work, I have always created websites. I created the first one in 2001. It was a simple set of several .html, .css and .js files dedicated to the work of my favorite science fiction writer.',
                    p4: 'Today, after 20 years, and many other projects, I still remember what he looked like. I remember the details of the workflow, I remember this spreadsheet layout in the notepad, I remember this feeling of magic, when everything you did suddenly comes to life and starts working. Awareness of the fact that the result of your work at any moment can be seen by any person on our planet.',
                    p5: 'This is probably why I consider web development my calling. This is what I truly love to this day.'
                }
            },
            contentContacts: {
                title: 'Contacts',
                info: 'At the moment I live in St. Petersburg (Russian Federation). I am always open to a wide variety of suggestions within my competence or beyond. My native language is Russian. Besides him, I speak pretty good English and a little bit of German.',
            }
        },
        DE: {
            contentIntro: {
                hello: 'Hallo! Ich bin Wladimir und ich bin ein Frontend Entwickler.',
                about: 'Ich verwende JavaScript mit Vue.js (und Nuxt.js) Framework. Dies ist nur meine Homepage und nichts weiter.\n' +
                    'Wenn Sie interessiert sind, finden Sie unten weitere Informationen über mich.',
                birds: 'Bitte erschrecken Sie meine Vögel nicht! Sie sind sehr empfindlich! ;)'
            },
            contentSkills: {
                title: 'In meinen Projekten benutze ich:',
                subtitle: 'Alles rund um die Webentwicklung:',
                tags: 'Hier sind einige andere Dinge, von denen ich weiß, dass ich sie verwende:',
                more: 'Was ist noch im Hintergrund:'
            },
            contentAbout: {
                title: 'Über mich',
                text: {
                    p1: '2005 absolvierte ich die Fakultät für Funktechnik der Staatlichen Technischen Universität Ural (heute ist sie Teil der Bundesuniversität Ural).',
                    p2: 'Seitdem habe ich in verschiedenen Bereichen der IT gearbeitet - ich war in der Entwicklung von Systemen für Telemetrie und Gerätesteuerung in Industrieunternehmen tätig, ich war Systemadministrator in verschiedenen Unternehmen, ich war Projektmanager in einem Telekommunikationsunternehmen.',
                    p3: 'Parallel zu meiner Hauptarbeit habe ich immer Websites erstellt. Ich habe den ersten im Jahr 2001 erstellt. Es war ein einfacher Satz von mehreren .html-, .css- und .js-Dateien, die der Arbeit meines Lieblings-Science-Fiction-Schriftstellers gewidmet waren.',
                    p4: 'Heute, nach 20 Jahren und vielen anderen Projekten, erinnere ich mich noch daran, wie er aussah. Ich erinnere mich an die Details des Workflows, ich erinnere mich an dieses Tabellenkalkulationslayout im Notizblock, ich erinnere mich an dieses Gefühl der Magie, als alles, was Sie getan haben, plötzlich zum Leben erwacht und zu arbeiten beginnt. Das Bewusstsein, dass das Ergebnis Ihrer Arbeit von jeder Person auf unserem Planeten jederzeit gesehen werden kann.',
                    p5: 'Dies ist wahrscheinlich der Grund, warum ich Webentwicklung als meine Berufung betrachte. Das liebe ich bis heute.'
                }
            },
            contentContacts: {
                title: 'Kontakte',
                info: 'Jetzt lebe ich in St. Petersburg (Russische Föderation). Ich bin immer offen für eine Vielzahl von Vorschlägen innerhalb meiner Kompetenz oder darüber hinaus. Meine Muttersprache ist Russisch. Neben ihm spreche ich ziemlich gut Englisch und ein bisschen Deutsch.',
            }
        }
    },
    getters: {
        GET_CURRENT_LANG: (state) => {
          return state.currentLang
        },
        GET_CONTENT: (state) => {
            if (state.currentLang === 'EN') return state.EN;
            if (state.currentLang === 'RU') return state.RU;
            if (state.currentLang === 'DE') return state.DE;
        }
    },
    mutations: {
        SET_LANG(state, lang) {
            state.currentLang = lang;
        },
    },
    actions: {
        setLang({commit}, lang) {
            commit('SET_LANG', lang);
        },
    },
    modules: {}

});

export default store;