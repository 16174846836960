<template>
  <div id="app">
    <Vanta
      :options="{
        mouseControls: true,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        scale: 1.00,
        scaleMobile: 1.00,
        backgroundColor: 0xffffff,
        color1: 0x2c3e50,
        color2: 0x68ffc7,
        colorMode: 'lerp',
        birdSize: 1.60,
        wingSpan: 34.00,
        quantity: 2.00
      }"
    />
    <Header />
    <b-container fluid class="main-wrapper" v-scroll="mainScroll">
      <MainInfo />
    </b-container>
    <Skills />
    <AboutMe />
    <Contacts />
  </div>
</template>

<script>
import AboutMe from "@/components/AboutMe";
import Contacts from "@/components/Contacts";
import MainInfo from "@/components/MainInfo";
import Header from "@/components/Header";
import Skills from "@/components/SkillList";
import Vanta from "@/components/Vanta";

export default {
  name: 'App',
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'theme-color', content: '#68ffc7' },
      { name: 'description', content: 'Меня зовут Владимир, ozed.dev - это мой сайт-визитка. Я занимаюсь фронтенд разработкой на JavaScript с использованием Vue.js и Nuxt.js' },
      { name: 'og:title', content: 'ozed.dev - фронтенд разработка на Vue.js и Nuxt.js' },
      { name: 'og:type', content: 'website' },
      { name: 'og:url', content: 'https://ozed.dev' },
      { name: 'og:description', content: 'Меня зовут Владимир, ozed.dev - это мой сайт-визитка. Я занимаюсь фронтенд разработкой на JavaScript с использованием Vue.js и Nuxt.js' },
    ],
    title: 'ozed.dev - фронтенд разработка на Vue.js и Nuxt.js',
    htmlAttrs: {
      lang: 'ru',
      amp: true
    }
  },
  components: {
    AboutMe,
    Contacts,
    Header,
    MainInfo,
    Skills,
    Vanta
  },
  computed: {
  },
  methods: {
    mainScroll: function (evt, el) {
      if (screen.width > 767) {
        if (window.scrollY > 80) {
          el.style.backgroundPositionX = 50 + (window.scrollY-80)/40 +'vw';
          // el.classList.add('glitch');
        } else {
          // el.classList.remove('glitch');
        }
      } else {
        if (window.scrollY > 20) {
          el.style.backgroundPositionX = 30 + (window.scrollY)/10 +'vw';
        } else {
          // el.classList.remove('glitch');
        }
      }

    },
  },
  beforeMount() {

  },
  mounted() {
    const anchors = document.querySelectorAll('a[href*="#"]')
    for (let anchor of anchors) {
      anchor.addEventListener('click', function (e) {
        e.preventDefault()
        const blockID = anchor.getAttribute('href').substr(1)
        document.getElementById(blockID).scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      })
    }
  }
}
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  ::-moz-selection  {
    background-color: #68ffc7;
    color: #2c3e50;
  }
  ::selection {
    background-color: #68ffc7;
    color: #2c3e50;
  }

  .main-wrapper {
    transition: all 0.5s ease-out;
  }

  h2 {
    font-weight: bold;
  }
  h5 {
    line-height: 1.8rem !important;
  }
  .underline {
    background: linear-gradient(#68ffc7, #68ffc7) no-repeat 0.8rem 1.4rem;
    background-size: 100% 0.95rem;
  }

  @font-face {
    font-family: icomoon;
    src: url("~@/assets/icons/icomoon/icomoon.ttf") format("truetype"),
    url("~@/assets/icons/icomoon/icomoon.woff") format("woff"),
    url("~@/assets/icons/icomoon/icomoon.svg") format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: block
  }
  [class*=" icon-"],
  [class^=icon-] {
    font-family: icomoon !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .text {
    font-size: 1.2rem !important;
  }

  @media (min-width: 768px) {
    h2 {
      font-size: 4rem !important;
    }
    .main-wrapper {
      background: url("assets/img/ozed.png") no-repeat 50vw bottom;
      background-size: 100vh;
      height: 100vh;
      min-height: 900px;
    }
  }
  @media (max-width: 767px) {
    h2 {
      font-size: 1.8rem !important;
      line-height: 2.4rem !important;
      font-weight: 700 !important;
    }
    h3 {
      font-size: 1.4rem !important;
      line-height: 2rem !important;
      font-weight: 700 !important;
    }
    .main-wrapper {
      background: url("assets/img/ozed.png") no-repeat 35vw bottom;
      background-size: 65vh;
      min-height: 100vh;
    }
    .arrow-link {
      margin-top: 8vh;
    }
    .vanta-canvas {
      display: none;
    }
  }

  .glitch{
    position:relative;
    overflow:hidden
  }
  .glitch:after,.glitch:before{
    content:"";
    display:block;
    position:absolute;
    top:0;
    left:0;
    min-width:100%;
    background: url("assets/img/ozed.png") no-repeat 50vw bottom;
    background-size: 100vh;
    height: 100vh;
    min-height: 900px;
  }
  .glitch:after{
    opacity:.2;
    animation:noise .9s linear infinite alternate-reverse
  }
  .glitch:before{
    opacity:.4;
    animation:noise 5s linear infinite reverse
  }
  @-webkit-keyframes noise{
    0%{
      background-position:50vw bottom;
      -webkit-filter:hue-rotate(0deg);
      filter:hue-rotate(0deg)
    }
    10%{
      background-position: 50vw bottom
    }
    20%{
      background-position: 51vw bottom
    }
    30%{
      background-position: 49vw bottom
    }
    40%{
      background-position: 51.5vw bottom
    }
    50%{
      background-position: 50.4vw bottom
    }
    60%{
      background-position: 49.5vw bottom
    }
    70%{
      background-position: 50vw bottom
    }
    80%{
      background-position: 51.8vw bottom
    }
    81%{
      background-position: 49.2vw bottom
    }
    to{
      background-position: 50vw bottom;
      -webkit-filter:hue-rotate(1turn);
      filter:hue-rotate(1turn)
    }
  }
  @keyframes noise{
    0%{
      background-position:50vw bottom;
      -webkit-filter:hue-rotate(0deg);
      filter:hue-rotate(0deg)
    }
    10%{
      background-position: 50vw bottom
    }
    20%{
      background-position: 51vw bottom
    }
    30%{
      background-position: 49vw bottom
    }
    40%{
      background-position: 51.5vw bottom
    }
    50%{
      background-position: 50.4vw bottom
    }
    60%{
      background-position: 49.5vw bottom
    }
    70%{
      background-position: 50vw bottom
    }
    80%{
      background-position: 51.8vw bottom
    }
    81%{
      background-position: 49.2vw bottom
    }
    to{
      background-position: 50vw bottom;
      -webkit-filter:hue-rotate(1turn);
      filter:hue-rotate(1turn)
    }
  }
</style>

