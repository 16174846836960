var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('Vanta',{attrs:{"options":{
      mouseControls: true,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      backgroundColor: 0xffffff,
      color1: 0x2c3e50,
      color2: 0x68ffc7,
      colorMode: 'lerp',
      birdSize: 1.60,
      wingSpan: 34.00,
      quantity: 2.00
    }}}),_c('Header'),_c('b-container',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.mainScroll),expression:"mainScroll"}],staticClass:"main-wrapper",attrs:{"fluid":""}},[_c('MainInfo')],1),_c('Skills'),_c('AboutMe'),_c('Contacts')],1)}
var staticRenderFns = []

export { render, staticRenderFns }