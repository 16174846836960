<template>
  <b-container class="header fixed-top" fluid v-scroll="scrollHeader">
    <b-container>
      <b-row class="ml-0 mr-0">
        <b-col class="text-left pl-0">
          <h1 class="logo underline"><a href="/">ozed.dev</a></h1>
        </b-col>
        <b-col ></b-col>
        <b-col class="lang pt-2 pr-0 text-right">
          <span
              @click="chooseLang('RU')"
              class="pr-1 mr-3 button-lang button-lang-ru"
              v-bind:class="{ 'active': (curLang === 'RU')}"
              v-b-tooltip.hover.bottomleft="'Вы говорите по-русски?'"
          >RU</span>
          <span
              @click="chooseLang('EN')"
              class="pr-1 mr-3 button-lang button-lang-en"
              v-bind:class="{ 'active': (curLang === 'EN') }"
              v-b-tooltip.hover.bottomleft="'Do you speak English?'"
          >EN</span>
          <span
              @click="chooseLang('DE')"
              class="pr-1 button-lang button-lang-de"
              v-bind:class="{ 'active': (curLang === 'DE')}"
              v-b-tooltip.hover.bottomleft="'Sprechen Sie Deutsch?'"
          >DE</span>
        </b-col>
      </b-row>
    </b-container>
  </b-container>

</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {

  name: "Header",

  data: () => ({
  }),

  computed: {

    ...mapGetters(['GET_CURRENT_LANG']),

    curLang() {
      return this.GET_CURRENT_LANG
    }

  },

  methods: {

    ...mapActions(['setLang']),

    chooseLang(lang) {
      this.setLang(lang);
      this.setCookie('lang', lang);
    },

    scrollHeader: function (evt, el) {
      window.scrollY > 80 ? el.classList.add('scrolled') : el.classList.remove('scrolled');
    },

    getCookie(param) {
      return this.$cookie.get(param)
    },

    setCookie(param, value) {
      let date = new Date;
      date.setDate(date.getDate() + 21);
      this.$cookie.set(param, value, { expires: date });
    }

  },

  created() {
    if (this.getCookie('lang')) {
      this.setLang(this.getCookie('lang'));
    } else

    if (
        window.navigator.language === "en-US" ||
        window.navigator.language === "en-EN" ||
        window.navigator.language === "en-GB" ||
        window.navigator.language === "en"
    ) {
      this.setLang('EN');
      console.log('EN from local');
    } else if (
        window.navigator.language === "ru-RU" ||
        window.navigator.language === "ru"
    ) {
      this.setLang('RU');
      console.log('RU from local');
    } else if (
        window.navigator.language === "de-DE" ||
        window.navigator.language === "de"
    ) {
      this.setLang('DE');
      console.log('DE from local');
    }

  }

}
</script>

<style scoped>
  .header {
    padding: 15px 0;
    z-index: 99;
    transition: all 0.2s ease;
    background: transparent;
  }
  .header.scrolled {
    background: #fff;
  }
  .logo {
    text-align: left;
    font-size: 1.8rem;
    font-weight: bold;
    display: inline;
    padding-right: 0.8rem;
  }
  .logo a {
    color: #2c3e50;
    text-decoration: none;
  }
  .logo:after {
    content: '';
  }

  .button-lang {
    width: 38px;
    height: 25px;
    border: none;
    cursor: pointer;
    font-weight: bold;
  }

  .button-lang.active,
  .button-lang:hover {
    background: linear-gradient(#68ffc7, #68ffc7) no-repeat 0.4rem 0.6rem;
    background-size: 100% 0.95rem;
    /*transition: 750ms ease;*/
  }
</style>