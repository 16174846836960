<template>
  <b-container id="main-info" class="d-flex">
    <b-row align-h="start" class="main-info-wrapper">
      <b-col cols="12"	sm="12"	md="8"	lg="8"	xl="8" class="d-flex flex-column flex-wrap">
        <transition-group name="slide" class="flex-grow-1" mode="out-in">
          <p class="intro-hello" :key="content.hello">{{ content.hello }}</p>
          <p class="intro-about" :key="content.about">{{ content.about }}</p>
          <p class="intro-birds" :key="content.birds">{{ content.birds }}</p>
        </transition-group>

      </b-col>
    </b-row>
    <Arrow
        :link="'#skills'"
        :options="'bottom'"
    />
  </b-container>
</template>

<script>
import Arrow from "@/components/Arrow";
import {mapGetters} from "vuex";

export default {
  name: "MainInfo",
  components: {
    Arrow
  },
  computed: {
    ...mapGetters(['GET_CONTENT']),
    content() {
      return this.GET_CONTENT.contentIntro
    },
  }
}
</script>

<style scoped>
  #main-info {
    text-align: left;
    min-height: 100vh;
  }
  .main-info-wrapper {
    padding-top: 15vh;
  }

  @media (min-width: 768px) {
    .intro-hello {
      font-size: 3rem;
      line-height: 3.8rem;
      font-weight: bold;
    }
    .intro-about {
      margin-top: 4rem;
      font-size: 1.5rem;
    }
    .intro-birds {
      margin-top: 4rem;
      font-size: 1.5rem;
      font-weight: bold;
      max-width: 60vw;
    }
  }
  @media (max-width: 767px) {
    .intro-hello {
      font-size: 1.8rem;
      line-height: 2.4rem;
      font-weight: 700;
    }
    .intro-about {
      margin-top: 2rem;
      font-size: 1.2rem;
      width: 65%;
    }
    .intro-birds {
      display: none;
    }
  }
</style>

<style>
  .slide {
    /*transition-delay: 2000ms !important;*/
  }
  .slide-enter {
    opacity: 0;
  }
  .slide-enter-to {
    /*position: absolute;*/
    opacity: 1;
  }
  .slide-leave {
    opacity: 0;
  }
  .slide-leave-to {
    opacity: 1;
  }
  .slide-enter-active,
  .slide-leave-active {
    transition: opacity 750ms ease
  }
</style>