<template>
  <b-container id="skills" fluid>
    <b-container>
      <transition name="slide" mode="out-in">
        <h2 class="text-left mb-5 slide" :key="content.title">{{ content.title }}</h2>
<!--      <h3 class="text-left mb-5">{{ content.subtitle }}</h3>-->
      </transition>
      <Skill
        :title="'JavaScript (ES5+)'"
        :value="100"
      />

      <Skill
        :title="'HTML (5)'"
        :value="100"
      />

      <Skill
        :title="'CSS (SASS/SCSS)'"
        :value="100"
      />

      <Skill
          :title="'Node.JS'"
          :value="90"
      />

      <Skill
        :title="'Vue.JS'"
        :value="85"
      />

      <Skill
          :title="'Nuxt.JS'"
          :value="70"
      />

      <Skill
        :title="'Strapi (backend)'"
        :value="50"
      />



      <transition name="slide" mode="out-in">
        <h3 class="text-left mb-3 mt-5" :key="content.tags">{{ content.tags }}</h3>
      </transition>
      <b-row>
        <b-col cols="12" md="8">
          <div class="text-left">
            <b-badge class="skill-badge">Webpack</b-badge>
            <b-badge class="skill-badge">npm</b-badge>
            <b-badge class="skill-badge">Vuex</b-badge>
            <b-badge class="skill-badge">GitHub</b-badge>
            <b-badge class="skill-badge">GitLab</b-badge>
            <b-badge class="skill-badge">BootstrapVue</b-badge>
            <b-badge class="skill-badge">UIkit</b-badge>
            <b-badge class="skill-badge">Bulma</b-badge>
            <b-badge class="skill-badge">Buefy</b-badge>
            <b-badge class="skill-badge">Materialize</b-badge>
            <b-badge class="skill-badge">Ionicons</b-badge>
            <b-badge class="skill-badge">MongoDB</b-badge>
            <b-badge class="skill-badge">DevTools</b-badge>
          </div>
        </b-col>
        <b-col cols="1" md="4">
          <Arrow
              :link="'#about'"
              :options="'right top'"
          />
        </b-col>
      </b-row>


<!-- <h3 class="text-left mb-3 mt-5">{{ content.more }}</h3>-->
<!--      <div class="text-left">-->
<!--        <b-badge class="skill-badge">С++</b-badge>-->
<!--        <b-badge class="skill-badge">Java</b-badge>-->
<!--      </div>-->

    </b-container>
  </b-container>
</template>

<script>
import Skill from "@/components/Skill";
import {mapGetters} from "vuex";
import Arrow from "@/components/Arrow";

export default {
  name: "Skills",
  components: {
    Arrow,
    Skill
  },
  data() {
    return {
      animate_p1: false
    }
  },
  props: {

  },
  computed: {
    ...mapGetters(['GET_CONTENT']),
    content() {
      return this.GET_CONTENT.contentSkills
    },
  },
  methods: {
    skillAnimation(toggle, item) {
      if (toggle) item.anim = true;
    }
  }
}
</script>

<style scoped>
  #skills {
    padding: 12vh 0;
    background: RGBA(0,0,0,0.05);
  }


</style>

<style>
  .skill-badge {
    background-color: #688c95 !important;
    cursor: pointer;
    padding: 0.5rem 0.7rem !important;
    margin-right: 0.8rem;
    font-size: 1rem !important;
    margin-bottom: 1rem;
    line-height: 1rem;
    transition: all 0.2s ease;
  }
  .skill-badge:hover {
    background-color: #68c1a0 !important;
  }
</style>