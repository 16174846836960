<template>
<b-container id="about" fluid>
  <b-container class="text-left">
    <transition name="slide" mode="out-in">
      <h2 :key="content.title">{{ content.title }}</h2>
    </transition>
    <b-row>
      <b-col cols="12">
        <div class="text mt-3 column">
<!--          <transition-group name="slide" mode="out-in">-->
            <p v-for="(key) in content.text" :key="key">{{ key }}</p>
<!--          </transition-group>-->
        </div>
      </b-col>
<!--      <b-col cols="1">-->
<!--        <Arrow-->
<!--            :link="'#contacts'"-->
<!--            :options="'right bottom white'"-->
<!--        />-->
<!--      </b-col>-->
    </b-row>

  </b-container>
</b-container>
</template>

<script>

import {mapGetters} from "vuex";


export default {
  name: "AboutMe",
  computed: {
    ...mapGetters(['GET_CONTENT']),
    content() {
      return this.GET_CONTENT.contentAbout
    }
  }
}
</script>
<style scoped>
  #about {
    padding: 12vh 0;
    background: RGBA(44, 62, 80, 0.9);
    color: #fff;


    min-height: 50vh;
  }
  .column {
    -webkit-column-width: 200px;
    -moz-column-width: 200px;
    column-width: 200px;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
  }
</style>