<template>
  <a
      v-bind:href="link"
      v-bind:class="options"
      class="arrow-link">

    <span class="arrow"></span>
  </a>
</template>

<script>
export default {
  name: "Arrow",
  props: {
    link: {
      type: String,
      required: false
    },
    options: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>
.arrow-link {
  color: #2c3e50;
  align-items: center;
  box-shadow: none;
  cursor: pointer;
  display: table;
  width: 44px;
  position: absolute;
  z-index: 1;
  bottom: 0;
}

.arrow-link.top {
  top: 0px;
}
.arrow-link.right {
  right: 25px;
}
.arrow-link.bottom {
  bottom: 0px;
}
.arrow-link.left {
  left: 25px;
}
.arrow-link.white .arrow {
  color: #fff;
}
.arrow-link .arrow {
  color: #2c3e50;
  display: table-cell;
  overflow: hidden;
  /*position: relative;*/
}
.arrow-link:hover .arrow {
  color: #68ffc7;
}
.arrow-link .arrow:before {
  padding-left: 5px;
  font-family: icomoon !important;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e908";
  position: absolute;
  left: 20px;
  top: 0;
  transform: translateX(-50%);
  transition: all .5s ease;
}
.arrow-link:hover .arrow:before {
  transform: translate(-50%, 20px);
}
@media (min-width: 768px) {
  .arrow-link {
    min-height: 200px;
  }
  .arrow-link .arrow:before {
    font-size: 10rem;
  }
}
@media (max-width: 767px) {
  .arrow-link {
    min-height: 120px;
  }
  .arrow-link .arrow:before {
    font-size: 6rem;
  }
}
</style>