<template>
  <div class="skill-wrapper"
       @mouseover="toggleAnimation(true)"
       @mouseleave="toggleAnimation(false)">
  <h5 class="text-left skill-title">{{ title }}</h5>
    <b-progress
        :value="value"
        show-value
        height="2.5rem"
        striped
        class="skill"
        :animated="animate"
    />
  </div>
</template>

<script>
export default {
  name: "Skill",
  data: () => {
    return {
      animate: false
    }
  },
  props: {
    title: {
      type: String,
      required: false
    },
    value: {
      type: Number,
      required: true
    },

  },
  methods: {
    toggleAnimation(bool) {
      this.animate = !!bool;

      // clearInterval(this.timer)
      // this.timer = null
      //
      // if (bool) {
      //   this.timer = setInterval(() => {
      //     this.value = this.value + Math.random();
      //   }, 2000)
      // }



    }
  }
}
</script>

<style scoped>
  .skill-wrapper {
    min-height: 6.5rem;
  }
  .skill-wrapper:hover {
    cursor: pointer;
  }
</style>

<style>
  .skill .progress-bar, .skill-badge {
    background-color: #688c95 !important;
    cursor: pointer;
  }
  .skill-wrapper:hover .skill {
    height: 3rem !important;
  }
  .skill-wrapper .skill {
    transition: all 0.2s ease;
  }
  .skill-wrapper:hover .skill .progress-bar {
    background-color: #68c1a0 !important;
    height: 3rem !important;
  }
</style>