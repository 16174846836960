<template>
    <b-container id="contacts" class="text-left pr-3 pl-3">
      <transition name="slide" mode="out-in">
        <h2 :key="content.title">{{ content.title }}</h2>
      </transition>
      <transition name="slide" mode="out-in">
        <p class="mt-3 text" :key="content.info">{{ content.info }}</p>
      </transition>
      <b-row class="mt-5">
        <b-col cols="12"	sm="12"	md="12"	lg="6"	xl="6">
          <div>
            <i class="icon-small icon-phone"></i>
            <a href="tel:+79827257120" class="contacts-link">+7 982 725-71-20</a>
          </div>
          <div>
            <i class="icon-small icon-mail"></i>
            <a href="mailto:ozed@ozed.dev" class="contacts-link">ozed@ozed.dev</a>
          </div>
          <div class="mt-3">
            <a href="https://api.whatsapp.com/send?phone=79827257120" class="icon-link" target="_blank"><i class="icon-big icon-whatsapp"></i></a>
            <a href="https://t.me/ozeddev" class="icon-link" target="_blank"><i class="icon-big icon-telegram"></i></a>
          </div>

          <div class="cpr">
            © 2020-{{ year }} ozed.dev
          </div>
        </b-col>
        <b-col cols="6"></b-col>
      </b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "Contacts",
  computed: {
    ...mapGetters(['GET_CONTENT']),
    content() {
      return this.GET_CONTENT.contentContacts
    },
    year() {
      const date = new Date();
      return date.getFullYear()
    }
  }

}
</script>

<style scoped>
  #contacts {
    color: #2c3e50;
    padding: 10vh 0 2vh;
    min-height: 300px;
  }
  .contacts-item {
    color: #2c3e50;
    font-size: 1.5rem;
  }
  .contacts-link {
    color: #2c3e50;
    font-size: 1.5rem;
    background: linear-gradient(#eaeaea, #eaeaea) no-repeat 0.8rem 1.4rem;
    background-size: 100% 0.95rem;
    padding-right: 0.5rem;
  }
  .contacts-link:hover {
    color: #2c3e50;
    text-decoration: none;
    background: linear-gradient(#68ffc7, #68ffc7) no-repeat 0.8rem 1.4rem;
    background-size: 100% 0.95rem;
  }
  .cpr {
    font-size: 1.25rem;
    margin-top: 3rem;
  }
  a.icon-link {
    text-decoration: none;
  }
  a.icon-link:hover i {
    color: #68c1a0;
  }
  .icon-small {
    font-size: 1.2rem;
    margin-right: 1rem;
  }
  .icon-big {
    color: #2c3e50;
    font-size: 2.5rem;
    margin-right: 1rem;
    transition: all 0.2s ease;
  }
  .icon-location:before {
    content:"\e906"
  }
  .icon-user:before {
    content:"\e902"
  }
  .icon-phone:before {
    content:"\e904"
  }
  .icon-mail:before {
    content:"\e903"
  }
  .icon-skype:before {
    content:"\e900"
  }
  .icon-telegram:before {
    content:"\e909"
  }
  .icon-whatsapp:before {
    content:"\e901"
  }
</style>